import { Typography } from "@material-ui/core"
import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="ACCESS/アクセス" />
      <div>
        <iframe
          title="アクセスマップ"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3226.4826502634896!2d139.4206733154838!3d36.032924980113876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018d4ed910be969%3A0xbecd2736d97ba1f1!2z576O5a655a6k44OT44OD44Kw44OY44OD44OJ!5e0!3m2!1sja!2sjp!4v1631968159639!5m2!1sja!2sjp"
          allowFullScreen=""
          loading="lazy"
          width="100%"
          height="450"
        ></iframe>
      </div>
      <Typography
        align="center"
        variant="body1"
        style={{
          color: "blue",
          fontSize: "2rem",
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "ヒラギノ丸ゴ ProN W4",
        }}
      >
        ※駐車場<span style={{ color: "red" }}>7</span>台分ございます。
      </Typography>
    </Layout>
  )
}

export default IndexPage
